import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import { useGetUsageQuery } from '../../hooks/api/usage/usage.generated';

const UsageDisplay = () => {
  const { data, loading, error } = useGetUsageQuery({
    variables: {
      input: {}, // This will fetch data for the entire subscription period
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!data || !data.getUsage) return <p>No data available</p>;

  const {
    dailyUsage,
    totalPlaysAllowed,
    totalPlaysUsed,
    totalViewsAllowed,
    totalViewsUsed,
    currentPlan,
  } = data.getUsage;

  const { startDate, endDate } = currentPlan;

  // Generate a complete list of dates within the subscription period and format them to just days
  const allDates = eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  }).map((date: Date) => format(date, 'yyyy-MM-dd'));

  // Create a map of usage data for quick lookup
  const usageMap = new Map(dailyUsage.map((item) => [format(item.date, 'yyyy-MM-dd'), item]));

  // Merge usage data with the complete list of dates
  const mergedUsage = allDates.map((date) => ({
    date,
    plays: usageMap.get(date)?.plays || 0,
    views: usageMap.get(date)?.views || 0,
  }));

  const remainingPlays = totalPlaysAllowed - totalPlaysUsed;
  const remainingViews = totalViewsAllowed - totalViewsUsed;

  const playsData = [
    { name: 'Used', value: totalPlaysUsed },
    { name: 'Remaining', value: remainingPlays },
  ];

  const viewsData = [
    { name: 'Used', value: totalViewsUsed },
    { name: 'Remaining', value: remainingViews },
  ];

  const COLORS = ['#0088FE', '#00C49F'];

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <h2 style={{ textAlign: 'left' }}>Usage Data</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <h3 style={{ textAlign: 'right' }}>Plan: {currentPlan.name}</h3>
          <h3 style={{ textAlign: 'right' }}>
            Start Date: {format(parseISO(startDate), 'MM/dd/yyyy')}
          </h3>
          <h3 style={{ textAlign: 'right' }}>
            End Date: {format(parseISO(startDate), 'MM/dd/yyyy')}
          </h3>
        </div>
      </div>

      {/* Daily Graph horizontal (desktop, tablet) */}
      {window.innerWidth >= 768 && (
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer width="100%" minWidth={700} height="80%">
            <BarChart width={600} height={300} data={mergedUsage}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                type="category"
                tickFormatter={(tick) => format(parseISO(tick), 'MM/dd')}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="plays" fill="#8884d8" />
              <Bar dataKey="views" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {/* Daily Graph vertical (mobile) */}
      {window.innerWidth < 768 && (
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <BarChart width={300} height={800} data={mergedUsage} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis
                dataKey="date"
                type="category"
                tickFormatter={(tick) => format(parseISO(tick), 'MM/dd')}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="plays" fill="#8884d8" />
              <Bar dataKey="views" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {/* Totals Display with Pie Charts */}
      <h3>Plays</h3>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          height: 300,
          // // paddingBottom: '15%',
          textAlign: 'center',
        }}
      >
        <div style={{ width: '45%' }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={playsData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {playsData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div style={{ width: '45%' }}>
          <h3 style={{ paddingTop: '10%' }}>Total Allowed: {totalPlaysAllowed}</h3>
          <h3>Used: {totalPlaysUsed}</h3>
          <h3>Remaining: {remainingPlays}</h3>
        </div>
      </div>
    </div>
  );
};

export default UsageDisplay;
