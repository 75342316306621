import React from 'react';
import { Box, List, Toolbar } from '@mui/material';
import { Closer2Icon } from '../assets/icons';
import { sidebarItems } from './config';
import { theme } from '../styles/theme';
import SidebarItem from './SideBarItem';

type PanelSidebarProps = {
  drawerWidth: number | string;
  isMobile: boolean;
  onItemClick?: () => void;
};

const PanelSidebar = React.memo(({ drawerWidth, isMobile, onItemClick }: PanelSidebarProps) => {
  return (
    <Box
      sx={{
        width: drawerWidth,
        backgroundColor: theme.palette.primary.main,
        height: '100%',
      }}
    >
      {!isMobile && (
        <Toolbar style={{ padding: '20px' }}>
          <Closer2Icon />
        </Toolbar>
      )}
      <List sx={{ paddingLeft: '10px' }}>
        {sidebarItems.map((item) => (
          <SidebarItem key={item.path} item={item} onItemClick={onItemClick} />
        ))}
      </List>
    </Box>
  );
});

export default PanelSidebar;
