import { Box, Paper } from '@mui/material';
import { FC, ReactNode } from 'react';
import { HomeTabEnum } from '../../../types';

interface CustomTabProps {
  currentTab: HomeTabEnum;
  tabToShow: HomeTabEnum;
  children: ReactNode;
}

export const CustomTab: FC<CustomTabProps> = ({ currentTab, children, tabToShow }) => {
  const isTabVisible = currentTab === tabToShow;
  return (
    <Paper hidden={!isTabVisible}>
      <Box mt={6} p={4}>
        {children}
      </Box>
    </Paper>
  );
};
