import React, { useState, useEffect } from 'react';
import { usePatchCustomerSettingsMutation } from '../../hooks/api/patchCustomerSettings/patchCustomerSettings.generated';
import { useCustomerSettingsQuery } from '../../hooks/api/customerSettings/customerSettings.generated';

const UpdateCustomerSettingsForm = () => {
  // Assuming customerId is passed as a prop
  const [trustedDomains, setTrustedDomains] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [apiKey, setApiKey] = useState('');

  // TODO refactor to use useCustomerSettings hook
  const [patchCustomerSettingsMutation, { loading: mutationLoading, error: mutationError }] =
    usePatchCustomerSettingsMutation();
  const { data, loading: queryLoading, error: queryError, refetch } = useCustomerSettingsQuery({});

  useEffect(() => {
    if (data) {
      setTrustedDomains(data.customerSettings.trustedDomains.join(', ')); // Assuming trustedDomains is an array
      if (data?.customerSettings?.crmIntegration?.freshworks?.isActive) {
        setIsActive(data.customerSettings.crmIntegration.freshworks.isActive);
      }
      if (data?.customerSettings?.crmIntegration?.freshworks?.apiKey) {
        setApiKey(data.customerSettings.crmIntegration.freshworks.apiKey);
      }
    }
  }, [data]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const input = {
      trustedDomains: trustedDomains.split(',').map((domain) => domain.trim()),
      crmIntegration: {
        freshworks: {
          isActive,
          apiKey,
        },
      },
    };

    await patchCustomerSettingsMutation({
      variables: {
        input,
      },
    }).then(() => {
      refetch();
    });
  };

  if (queryLoading) return <p>Loading...</p>;
  if (queryError) return <p>Error: {queryError.message}</p>;

  return (
    <form onSubmit={handleSubmit}>
      {/* Form fields and submit button */}
      <div>
        <label>Trusted Domains (comma-separated):</label>
        <input
          type="text"
          value={trustedDomains}
          onChange={(e) => setTrustedDomains(e.target.value)}
        />
      </div>
      <div>
        <label>
          Freshworks Active:
          <input
            type="checkbox"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
        </label>
      </div>
      <div>
        <label>Freshworks API Key:</label>
        <input type="text" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
      </div>
      <button type="submit" disabled={mutationLoading}>
        Update Settings
      </button>
      {mutationLoading && <p>Updating...</p>}
      {mutationError && <p>Error updating customer settings: {mutationError.message}</p>}
    </form>
  );
};

export default UpdateCustomerSettingsForm;
