import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import HomeTopPanel from './HomeTopPanel';
import { HomeTabEnum } from '../../types';
import CustomerInformation from './HomeTabs/CustomerInformation';
import { CustomTab } from './HomeTabs/CustomTab';
import { CustomerVideosView } from './HomeTabs/CustomerVideoTab';

const Home = () => {
  const [currentTab, setCurrentTab] = useState(HomeTabEnum.Video);
  const [isEditable, setIsEditable] = useState(false);

  const handleEditClick = () => setIsEditable(true);

  const handleChange = (event: React.SyntheticEvent, newValue: HomeTabEnum) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <HomeTopPanel
        currentTab={currentTab}
        customerName="TEST" // @TODO: Set proper
        isEditable={isEditable}
        onEditClick={handleEditClick}
      />
      <Tabs
        aria-label="secondary tabs example"
        indicatorColor="secondary"
        onChange={handleChange}
        style={{ textTransform: 'none', color: '#653FFE' }}
        textColor="secondary"
        value={currentTab}
      >
        <Tab
          label="Videos"
          style={{ textTransform: 'none', fontWeight: '700' }}
          value={HomeTabEnum.Video}
        />
        <Tab
          label="Customer information"
          style={{ textTransform: 'none', fontWeight: '700' }}
          value={HomeTabEnum.CustomerInformation}
        />
      </Tabs>
      <CustomTab currentTab={currentTab} tabToShow={HomeTabEnum.Video}>
        <CustomerVideosView />
      </CustomTab>
      <CustomTab currentTab={currentTab} tabToShow={HomeTabEnum.CustomerInformation}>
        <CustomerInformation closeEditMode={() => setIsEditable(false)} isEditable={isEditable} />
      </CustomTab>
    </Box>
  );
};

export default Home;
