import { FC, useMemo } from 'react';
import { Box, TablePagination, useMediaQuery } from '@mui/material';
import CustomerVideosTable from './CustomerVideosTable';
import { createMovieData, getComparator, stableSort } from '../../../../utils';
import { usePagination } from '../../../../hooks';
import { MovieType } from '../../../../interfaces';
import { theme } from '../../../../styles/theme';
import CustomerVideosMobileAccordion from './CustomerVideosMobileAccordion';
import { useMoviesQuery } from '../../../../hooks/api/movies/movies.generated';

const CustomerVideosView: FC = () => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  } = usePagination();

  const { data: moviesData } = useMoviesQuery();

  const rows =
    moviesData?.movies.movies.map((movie) =>
      createMovieData(
        movie.id ?? '',
        movie.name ?? '',
        movie.url ?? '',
        movie.numberOfViews ?? 0,
        movie.numberOfClicks ?? 0,
        movie.viewsInFull ?? 0,
        movie.ctaClicks ?? 0,
        movie.isActive ?? false,
      ),
    ) || [];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows as any[], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ) as Partial<MovieType>,
    [order, orderBy, page, rowsPerPage, rows],
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      {!isMobile ? (
        <>
          <CustomerVideosTable
            emptyRows={emptyRows}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy as keyof Partial<MovieType>}
            visibleRows={visibleRows as Partial<MovieType>[]}
          />
          <TablePagination
            component="div"
            count={rows.length}
            labelRowsPerPage=""
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </>
      ) : (
        <CustomerVideosMobileAccordion rows={rows} />
      )}
    </Box>
  );
};
export default CustomerVideosView;
