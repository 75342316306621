import React, { useState } from 'react';
import { Alert, Box, Container, Link, Typography } from '@mui/material';
import { appConfig } from '../../app-config';

const VerifyEmail: React.FC = () => {
  const [emailSent, setEmailSent] = useState(false);

  const handleVerifyEmail = () => {
    // TODO: Implement logic to send verification email
    setEmailSent(true);
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        <Typography variant="h4" align="center" gutterBottom>
          Verify Email
        </Typography>
        {emailSent ? (
          <Alert severity="info">
            An email has been sent to your inbox. Please follow the instructions to verify your
            email address.
          </Alert>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              An email has been sent to your inbox. Please follow the instructions to verify your
              email address.
            </Typography>
            {/* <Button variant="contained" color="primary" onClick={handleVerifyEmail}>
              Resend Email
            </Button> */}
          </>
        )}
        <Typography variant="body2" align="center" mt={2}>
          Did not receive the email? Check your spam folder or
          <Link href={appConfig.supportEmail} target="_blank" rel="noopener">
            contact support
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  );
};

export default VerifyEmail;
