import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import VerifyEmail from './components/Onboarding/VerifyEmail';
import { AppRouter } from './pages';
import PanelLayout from './layout';
import { GraphQLProvider } from './providers/GraphQLProvider';
import './styles/index.css';
import { SettingsProvider } from './contexts/CustomerSettingsContext';
import { LoadingSpinner } from './components/Utils';
import SignupForm from './components/Onboarding/Signup';
import { SubscriptionPlansList } from './components/Onboarding/SubscriptionPlan';
import { Checkout } from './components/Onboarding/Checkout';
import Login from './components/Onboarding/Login';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return isAuthenticated ? children : null;
};

function AppContent() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      {/* Public route for signup */}
      <Route path="/signup" element={<SignupForm />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/login" element={<Login />} />

      {/* Protected routes for authenticated users */}
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <PanelLayout>
              <AppRouter />
            </PanelLayout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

function App() {
  return (
    <Elements stripe={stripePromise}>
      <GraphQLProvider>
        <SettingsProvider>
          <AppContent />
        </SettingsProvider>
      </GraphQLProvider>
    </Elements>
  );
}

export default App;
