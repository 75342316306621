import { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableViewToolbar from '../Common/TableViewToolbar';
import {
  ControllersDocument,
  useControllersQuery,
} from '../../hooks/api/controllers/controllers.generated';
import { useDeleteControllerMutation } from '../../hooks/api/deleteController/deleteController.generated';

const ControllersView = () => {
  // TODO pagination?
  // const {
  //   order,
  //   orderBy,
  //   page,
  //   rowsPerPage,
  //   handleChangeRowsPerPage,
  //   handleChangePage,
  //   handleRequestSort,
  // } = usePagination();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const [errorNotification, setErrorNotification] = useState(false);
  const [backendError, setBackendError] = useState<string | null>(null);

  useEffect(() => {
    setErrorNotification(true);
  }, [backendError]);

  const flexDirectionValue = isLargeScreen ? 'row' : 'column';

  const { data: controllersData, error: controllersError } = useControllersQuery({});

  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (controllersError) {
      console.error('Failed to load controllers', controllersError);
      setBackendError(
        'Failed to load controllers due to issues with the server. Please try again later or contact us.',
      );
    }

    if (controllersData) {
      setRows(
        controllersData?.controllers.controllers.map((a) => ({
          previewImage: 'https://www.clapa.com/wp-content/uploads/2019/06/video-placeholder.jpg', // TODO in the future
          id: a.id,
          customerId: a.customerId,
          name: a.name,
          createdAt: a.createdAt,
          updatedAt: a.updatedAt,
          deletedAt: a.deletedAt,
          isActive: a.isActive,
          validFrom: a.validFrom,
          validUntil: a.validUntil,
          url: a.url,
        })),
      );
    }
  }, [controllersData, controllersError]);

  const [deleteControllerMutation] = useDeleteControllerMutation({
    refetchQueries: [{ query: ControllersDocument }],
  });

  const handleRemoveController = async (controllerId: string) => {
    try {
      await deleteControllerMutation({
        variables: {
          id: controllerId,
        },
      });
    } catch (err) {
      console.error('create/update failed', (err as unknown as ApolloError).message);
      setBackendError(
        'Failed to remove controller due to issues with the server. Please try again later or contact us.',
      );
    }
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Box
        display="flex"
        flexDirection={flexDirectionValue}
        justifyContent="space-between"
        pt="59px"
        width="100%"
      >
        <TableViewToolbar title="Controllers" />
        <Button
          color="secondary"
          onClick={() => navigate('/controllers/add-new-controller')}
          style={{
            padding: isLargeScreen ? '0 90px' : '10px 20px',
            textTransform: 'none',
            maxWidth: isLargeScreen ? '100%' : '30%',
            maxHeight: '48px',
          }}
          variant="outlined"
        >
          New controller
        </Button>
      </Box>

      <Grid container spacing={2}>
        {rows.map((controller) => (
          <Grid key={controller.id} item lg={3} md={4} sm={6} xs={12}>
            <Card>
              <CardMedia
                alt={controller.name}
                component="img"
                height="140"
                image={controller.previewImage}
              />
              <CardContent>
                <Typography component="div" variant="h6">
                  {controller.name}
                </Typography>
                {/* <Typography color="text.secondary" variant="body2">
                  {controller.videoController.name}
                </Typography> */}
              </CardContent>
              <CardActions>
                <IconButton aria-label="edit">
                  <Button onClick={() => navigate(`/controllers/${controller.id}`)} size="small">
                    Edit
                  </Button>
                </IconButton>
                <IconButton aria-label="edit">
                  <Button onClick={() => handleRemoveController(controller.id)} size="small">
                    Remove
                  </Button>
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {backendError && (
        <Snackbar
          autoHideDuration={5000}
          onClose={() => setErrorNotification(false)}
          open={errorNotification}
        >
          <Alert severity="error">
            <div>{backendError}</div>
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};
export default ControllersView;
