import React, { useState } from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import { Box, Typography, Popover, Button } from '@mui/material';

interface ColorPickerProps {
  color: string | null;
  gradientColors?: string[] | null;
  onChangeComplete: (color: string | null, gradientColors: string[] | null) => void;
  label?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  color,
  gradientColors,
  onChangeComplete,
  label,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [localColors, setLocalColors] = useState<string[]>(gradientColors || [color || '#000000']);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeComplete = (colorResult: ColorResult, index: number) => {
    const newColors = [...localColors];
    newColors[index] = colorResult.hex;
    setLocalColors(newColors);

    if (newColors.length === 1) {
      onChangeComplete(newColors[0], null);
    } else {
      onChangeComplete(null, newColors);
    }
  };

  const handleAddColor = () => {
    if (localColors.length < 2) {
      setLocalColors([...localColors, '#ffffff']);
    }
  };

  const handleRemoveColor = () => {
    if (localColors.length > 1) {
      const newColors = localColors.slice(0, -1);
      setLocalColors(newColors);
      onChangeComplete(newColors[0], null);
    }
  };

  const open = Boolean(anchorEl);

  const gradientStyle =
    localColors.length > 1
      ? { background: `linear-gradient(to right, ${localColors[0]}, ${localColors[1]})` }
      : { background: localColors[0] };

  return (
    <Box>
      {label && <Typography variant="body2">{label}</Typography>}
      <Box
        onClick={handleClick}
        sx={{
          width: 36,
          height: 14,
          borderRadius: 1,
          border: '2px solid #fff',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          ...gradientStyle,
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          {localColors.map((c, index) => (
            <Box key={index} mb={2}>
              <Typography variant="body2">Color {index + 1}</Typography>
              <SketchPicker
                color={c}
                onChangeComplete={(color2) => handleChangeComplete(color2, index)}
              />
            </Box>
          ))}
          <Box mt={2}>
            <Button onClick={handleAddColor} disabled={localColors.length >= 2}>
              Add Color
            </Button>
            <Button onClick={handleRemoveColor} disabled={localColors.length <= 1}>
              Remove Color
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default ColorPicker;
