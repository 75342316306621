import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { HomeTabEnum } from '../../types';
import useFeatureFlags from '../../hooks/useFeatureFlags';

interface HomeTopPanelProps {
  currentTab: HomeTabEnum;
  onEditClick: () => void;
  isEditable: boolean;
  customerName?: string;
}

const HomeTopPanel: FC<HomeTopPanelProps> = ({
  currentTab,
  onEditClick,
  isEditable,
  customerName = 'No customer name',
}) => {
  const featureFlags = useFeatureFlags();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const flexDirectionValue = isLargeScreen ? 'row' : 'column';

  return (
    <Box
      display="flex"
      flexDirection={flexDirectionValue}
      justifyContent="space-between"
      mb={6}
      mt="59px"
      width="100%"
    >
      <Typography fontSize={36} fontWeight={700}>
        {customerName}
      </Typography>
      {featureFlags.customerForm && (
        <Box>
          {currentTab === HomeTabEnum.CustomerInformation && !isEditable && (
            <Button
              color="secondary"
              onClick={onEditClick}
              style={{
                padding: isLargeScreen ? '0 90px' : '10px 20px',
                textTransform: 'none',
                maxWidth: isLargeScreen ? '100%' : '30%',
              }}
              variant="outlined"
            >
              Edit
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default HomeTopPanel;
