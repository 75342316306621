import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TablePagination,
} from '@mui/material';
import { useGetViewerDataQuery } from '../../hooks/api/viewerData/viewerData.generated';
import {
  ControllersQuery,
  useControllersQuery,
} from '../../hooks/api/controllers/controllers.generated';
import ViewerDataTable from './ViewerDataTable';
import { usePagination } from '../../hooks';
import { useExcel } from '../../hooks/api/useExcel';

// TODO move it to some commons
export default function ControllerSelect({
  selectedControllerId,
  controllersData,
  setSelectedControllerId,
}: {
  selectedControllerId?: string;
  controllersData: ControllersQuery;
  setSelectedControllerId: (newValue: string) => void;
}) {
  const handleControllerChange = (event: SelectChangeEvent) => {
    setSelectedControllerId(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="controller-select-label">Select a Controller</InputLabel>
        <Select
          labelId="controller-select-label"
          id="controllerSelect"
          value={selectedControllerId}
          label="Select a Controller"
          onChange={handleControllerChange}
          style={{
            // padding: isLargeScreen ? '0 90px' : '10px 20px',
            // maxWidth: isLargeScreen ? '100%' : '30%',
            padding: '0 90px',
            maxWidth: '100%',
            textTransform: 'none',
            maxHeight: '48px',
            marginRight: '10px',
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {controllersData?.controllers.controllers.map((controller) => (
            <MenuItem key={controller.id} value={controller.id}>
              {controller.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

// TODO add sorting

export const ViewerDataPage: React.FC = () => {
  const [selectedControllerId, setSelectedControllerId] = useState<string | undefined>();
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  } = usePagination();

  const { exportToXlsx } = useExcel();

  const limit = rowsPerPage;
  const sortBy = orderBy;
  const sortOrder = order;

  // TODO that may need optimisation to not fetch controllers every time!
  // Fetch controllers
  const { data: controllersData, error: controllersError } = useControllersQuery({});

  // Use the useGetViewerDataQuery hook with variables
  const { data, loading, error, refetch } = useGetViewerDataQuery({
    variables: {
      page,
      limit,
      sortBy,
      sortOrder,
    },
  });

  useEffect(() => {
    refetch({ controllerId: selectedControllerId });
  }, [selectedControllerId]);

  const handleExportClick = useCallback(() => {
    if (!data || !data.viewerData || !data.viewerData.data) return;
    exportToXlsx({
      fileName: `viewer-data-report-${new Date().toISOString()}.xlsx`,
      sheets: [
        {
          name: 'Viewer Data',
          headers: [
            'Submission Type',
            'Email',
            'Phone',
            'Text',
            'Status',
            'Created At',
            'ControllerId',
            'StageId',
          ],
          data: data.viewerData.data.map((item) => [
            item.submissionType,
            item.data.email || '',
            item.data.phone || '',
            item.data.text || '',
            item.status,
            new Date(item.createdAt).toLocaleString(),
            item.controllerId,
            item.stageId,
          ]),
        },
      ],
    });
  }, [exportToXlsx, data]);

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <Snackbar autoHideDuration={6000} open>
        <Alert severity="error">Error: Connecting to backend. [{error.message}]</Alert>
      </Snackbar>
    );

  return (
    <div>
      <h1>Viewer Data</h1>
      {/* // TODO implement error handling */}
      <Box display="flex">
        {controllersData && (
          <ControllerSelect
            controllersData={controllersData}
            selectedControllerId={selectedControllerId}
            setSelectedControllerId={setSelectedControllerId}
          />
        )}

        <Button
          color="secondary"
          onClick={handleExportClick}
          style={{
            // padding: isLargeScreen ? '0 90px' : '10px 20px',
            // maxWidth: isLargeScreen ? '100%' : '30%',
            padding: '0 90px',
            maxWidth: '100%',
            textTransform: 'none',
            maxHeight: '48px',
            marginRight: '10px',
          }}
          variant="outlined"
        >
          Export to excel
        </Button>
      </Box>

      {loading ? (
        <p>Loading...</p>
      ) : (
        data && (
          <>
            <ViewerDataTable data={data} />
            <TablePagination
              component="div"
              count={data.viewerData.meta?.total || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </>
        )
      )}
    </div>
  );
};
