import { Box, Paper, Typography } from '@mui/material';
import UpdateCustomerSettingsForm from './UpdateCustomerSettingsForm';

const Settings = () => {
  return (
    <Box>
      <Typography fontSize={36} fontWeight={700} pb="24px" pt="44px">
        Settings
      </Typography>
      <Paper style={{ padding: '32px', height: '70vh' }}>
        <UpdateCustomerSettingsForm />
      </Paper>
    </Box>
  );
};

export default Settings;
