/* eslint-disable react/destructuring-assignment */
export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
}

const SVGIcon = ({ ...props }: SVGProps) => {
  return <svg {...props} />;
};

export type IconType = typeof SVGIcon;

export const UsageIcon: IconType = ({ height = 48, width = 48, color = '#FFFFFF' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} height={height} width={width}>
    <rect x="4" y="10" width="4" height="10" />
    <rect x="10" y="6" width="4" height="14" />
    <rect x="16" y="2" width="4" height="18" />
  </svg>
);

export const ReplayIcon: IconType = ({ height = 48, width = 48, color = '#FFFFFF' }) => (
  <svg height={height} viewBox="0 0 122.88 118.66" width={width} x="0px" y="0px">
    <g>
      <path
        d="M16.68,22.2c-1.78,2.21-3.43,4.55-5.06,7.46C5.63,40.31,3.1,52.39,4.13,64.2c1.01,11.54,5.43,22.83,13.37,32.27 c2.85,3.39,5.91,6.38,9.13,8.97c11.11,8.93,24.28,13.34,37.41,13.22c13.13-0.12,26.21-4.78,37.14-13.98 c3.19-2.68,6.18-5.73,8.91-9.13c6.4-7.96,10.51-17.29,12.07-27.14c1.53-9.67,0.59-19.83-3.07-29.66 c-3.49-9.35-8.82-17.68-15.78-24.21C96.7,8.33,88.59,3.76,79.2,1.48c-2.94-0.71-5.94-1.18-8.99-1.37c-3.06-0.2-6.19-0.13-9.4,0.22 c-2.01,0.22-3.46,2.03-3.24,4.04c0.22,2.01,2.03,3.46,4.04,3.24c2.78-0.31,5.49-0.37,8.14-0.19c2.65,0.17,5.23,0.57,7.73,1.17 c8.11,1.96,15.1,5.91,20.84,11.29c6.14,5.75,10.85,13.12,13.94,21.43c3.21,8.61,4.04,17.51,2.7,25.96 C113.59,75.85,110,84,104.4,90.96c-2.47,3.07-5.12,5.78-7.91,8.13c-9.59,8.07-21.03,12.15-32.5,12.26 c-11.47,0.11-23-3.76-32.76-11.61c-2.9-2.33-5.62-4.98-8.13-7.97c-6.92-8.22-10.77-18.09-11.65-28.2 c-0.91-10.38,1.32-20.99,6.57-30.33c1.59-2.82,3.21-5.07,5.01-7.24l0.53,14.7c0.07,2.02,1.76,3.6,3.78,3.53 c2.02-0.07,3.6-1.76,3.53-3.78l-0.85-23.42c-0.07-2.02-1.76-3.59-3.78-3.52c-0.13,0.01-0.25,0.02-0.37,0.03v0l-22.7,3.19 c-2,0.28-3.4,2.12-3.12,4.13c0.28,2,2.12,3.4,4.13,3.12L16.68,22.2L16.68,22.2L16.68,22.2z M85.78,58.71L53.11,80.65V37.12 L85.78,58.71L85.78,58.71z"
        fill={color}
      />
    </g>
  </svg>
);

export const EditIcon: IconType = (props) => (
  <svg
    fill="#000000"
    height={props.height ?? 20}
    version="1.1"
    viewBox="0 0 494.936 494.936"
    width={props.width ?? 20}
  >
    <g>
      <g>
        <path
          d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157
			c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21
			s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741
			c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"
        />
        <path
          d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069
			c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963
			c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692
			C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107
			l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005
			c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"
        />
      </g>
    </g>
  </svg>
);

export const VideoIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="20" viewBox="0 0 20 20" width="20">
    <path
      d="M15.8333 20H4.16666C1.83333 20 0 18.1667 0 15.8333V4.16667C0 1.83333 1.83333 0 4.16666 0H15.8333C18.1667 0 20 1.83333 20 4.16667V15.8333C20 18.0833 18.0833 20 15.8333 20ZM4.16666 1.66667C2.75 1.66667 1.66667 2.75 1.66667 4.16667V15.8333C1.66667 17.25 2.75 18.3333 4.16666 18.3333H15.8333C17.25 18.3333 18.3333 17.25 18.3333 15.8333V4.16667C18.3333 2.75 17.25 1.66667 15.8333 1.66667H4.16666ZM7.75 14.1667C7.41666 14.1667 7.08332 14.0833 6.74999 13.9167C6.16666 13.5833 5.75 12.9167 5.75 12.25V7.83333C5.75 6.75 6.58333 5.91667 7.66666 5.91667C7.99999 5.91667 8.33333 6 8.66667 6.16667L13.0833 8.33333C14 8.83333 14.4166 10 13.8333 11C13.6666 11.3333 13.4167 11.5833 13.0833 11.75L8.58333 14C8.41666 14.0833 8.08333 14.1667 7.75 14.1667ZM7.75 7.5C7.66666 7.5 7.66665 7.5 7.58332 7.5C7.49999 7.58333 7.41667 7.66666 7.41667 7.75V12.1667C7.41667 12.3333 7.58333 12.4167 7.66666 12.4167C7.74999 12.4167 7.75 12.4167 7.83334 12.4167L12.3333 10.1667C12.4167 10.0833 12.4167 10 12.4167 9.91667C12.4167 9.83333 12.3333 9.75 12.25 9.66667L7.75 7.5C7.83333 7.5 7.83333 7.5 7.75 7.5Z"
      fill={props.fill ?? 'white'}
    />
  </SVGIcon>
);

export const BackArrowIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="23" viewBox="0 0 24 23" width="24">
    <path
      d="M0 11.4901C0 11.2636 0.0445565 11.0457 0.124735 10.8453C0.204913 10.6101 0.329637 10.3835 0.516719 10.1919L9.76391 0.546555C10.4321 -0.150486 11.5546 -0.185361 12.2673 0.468115C12.98 1.12159 13.0156 2.21943 12.3475 2.91647L5.79065 9.75621H22.2272C23.2071 9.75621 24 10.5317 24 11.4901C24 12.4485 23.2071 13.224 22.2272 13.224H5.77284L12.3475 20.0811C13.0156 20.7782 12.98 21.876 12.2673 22.5295C11.9287 22.8431 11.4922 23 11.0557 23C10.5835 23 10.1114 22.817 9.76391 22.4511L0.516719 12.8058C0.294002 12.5792 0.160362 12.3004 0.0890923 12.0129C0.0445489 11.8473 0 11.6731 0 11.4901Z"
      fill="#653FFE"
    />
  </SVGIcon>
);

export const Closer2Icon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="100%" viewBox="0 0 153 24" width="100%">
    <rect fill="#441078" height="100%" width="100%" />
    <path
      d="M130.152 0C129.138 0 128.053 0.65054 128.053 2.06172C128.053 3.4829 129.138 4.12343 130.152 4.12343C131.167 4.12343 132.252 3.47289 132.252 2.06172C132.252 0.640531 131.157 0 130.152 0Z"
      fill="white"
    />
    <path d="M131.871 7.17578H128.434V23.4493H131.871V7.17578Z" fill="white" />
    <path
      d="M144.47 6.89551C139.587 6.89551 136.04 10.4585 136.04 15.3626C136.04 20.3467 139.517 23.8296 144.5 23.8296C149.504 23.8296 153 20.3467 153 15.3626C153.01 10.4585 149.413 6.89551 144.47 6.89551ZM144.51 20.7471C141.054 20.7471 139.476 17.9547 139.476 15.3626C139.476 12.6703 141.204 9.93804 144.51 9.93804C147.655 9.93804 149.544 12.7003 149.544 15.3626C149.534 17.9547 147.966 20.7471 144.51 20.7471Z"
      fill="white"
    />
    <path
      d="M8.6909 20.5574C6.13889 20.5574 3.55674 18.936 3.55674 15.303C3.55674 12.2204 5.74705 9.98854 8.76123 9.98854C9.94681 9.98854 11.1726 10.4289 12.1472 11.2096L14.3777 8.87761C12.7199 7.33633 11.0118 6.67578 8.70095 6.67578C3.41608 6.67578 0 10.0686 0 15.303C0 20.5473 3.41608 23.9402 8.70095 23.9402C11.1424 23.9402 13.0213 23.2096 14.7896 21.5782L12.4184 19.2763C11.4439 20.117 10.1678 20.5574 8.6909 20.5574Z"
      fill="white"
    />
    <path d="M21.9533 1.86133H18.4167V23.4193H21.9533V1.86133Z" fill="white" />
    <path
      d="M34.1306 6.81543C29.2275 6.81543 25.6708 10.3884 25.6708 15.3125C25.6708 20.3167 29.1672 23.8096 34.1607 23.8096C39.1844 23.8096 42.6909 20.3167 42.6909 15.3125C42.6909 10.3884 39.0839 6.81543 34.1306 6.81543ZM34.1607 20.5969C30.7748 20.5969 29.2376 17.8546 29.2376 15.3125C29.2376 12.6703 30.9255 9.99808 34.1607 9.99808C37.2452 9.99808 39.0839 12.7003 39.0839 15.3125C39.0839 17.8546 37.5467 20.5969 34.1607 20.5969Z"
      fill="white"
    />
    <path
      d="M52.8286 13.6716C50.4976 13.5215 49.3623 12.8209 49.3623 11.5098C49.3623 10.2388 50.6082 9.51815 52.7784 9.51815C54.5366 9.51815 55.8227 9.9385 56.948 10.8893L58.8771 8.6274C57.1992 7.24625 55.3907 6.67578 52.7181 6.67578C50.1761 6.67578 45.8759 7.71665 45.8759 11.6399C45.8759 15.4931 50.0958 16.3839 52.6076 16.574C54.5065 16.6841 56.2748 17.0144 56.2748 18.6658C56.2748 19.1762 56.0638 19.6566 55.6519 20.0569C55.0189 20.6775 53.984 21.0277 52.8889 21.0077C51.1608 20.9777 48.5183 20.137 47.0916 18.8159L45.3936 21.2679C47.815 23.6699 50.3972 24.0002 52.8286 24.0002C57.9829 24.0002 59.8115 21.1779 59.8115 18.7659C59.7914 14.6424 56.0638 13.8718 52.8286 13.6716Z"
      fill="white"
    />
    <path
      d="M71.4261 20.6975C68.432 20.6975 66.3723 19.2162 66.0508 16.8242L66.0006 16.4639H79.3233C79.5745 13.2713 78.8913 10.7091 77.354 9.01773C75.9273 7.45643 73.7471 6.63574 71.0443 6.63574C65.8398 6.63574 62.3434 10.0586 62.3434 15.1629C62.3434 20.5273 65.7996 23.8601 71.3558 23.8601C73.9982 23.8601 76.6005 22.9293 78.198 21.4281L75.9575 19.2262C74.802 20.2371 72.8227 20.6975 71.4261 20.6975ZM66.1312 13.2312C66.7541 11.0294 68.643 9.7083 71.185 9.7083C73.8978 9.7083 75.6158 11.0094 75.9072 13.2713L75.9474 13.6216H66.0207L66.1312 13.2312Z"
      fill="white"
    />
    <path
      d="M91.2795 10.2288C92.6459 10.2288 93.4598 10.5891 94.2033 11.1595L95.8109 8.07696C94.8664 7.24626 93.3191 6.71582 91.8321 6.71582C90.2145 6.71582 88.5567 6.98605 87.3209 8.9677L86.8688 9.6883L86.4669 7.16619H83.1613V23.4398H86.7281V14.8426C86.7382 11.9502 88.4361 10.2288 91.2795 10.2288Z"
      fill="white"
    />
    <path
      d="M102 7.98685C102.251 5.80503 104.803 4.62404 107.144 4.62404C110.771 4.62404 112.931 6.17534 112.931 8.77751C112.931 10.3288 111.977 12.1803 107.456 12.1803C103.095 12.1803 98.0012 14.1019 98.0012 19.5365V23.5198H116.88V19.9368H101.91V19.5765C101.91 16.8642 105.155 15.6432 107.456 15.6432C111.806 15.6432 116.81 14.8526 116.81 8.68743C116.81 3.28293 111.786 1.36133 107.074 1.36133C102.743 1.36133 98.3327 3.41304 98.1117 7.99686H102V7.98685Z"
      fill="#00DF81"
    />
    <path
      d="M124.194 19.9865C123.803 19.6462 123.27 19.4561 122.697 19.4561C121.652 19.4561 120.537 20.1266 120.537 21.5878C120.537 23.0491 121.663 23.7196 122.697 23.7196C123.732 23.7196 124.858 23.0491 124.858 21.5878C124.868 20.9173 124.637 20.3668 124.194 19.9865Z"
      fill="white"
    />
  </SVGIcon>
);

export const CustomerIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="22" viewBox="0 0 17 22" width="17">
    <path
      d="M8.49911 11C11.6155 11 14.1652 8.69375 14.1652 5.875C14.1652 3.05625 11.6155 0.75 8.49911 0.75C5.38275 0.75 2.83301 3.05625 2.83301 5.875C2.83301 8.69375 5.38275 11 8.49911 11ZM8.49911 2.37291C10.5767 2.37291 12.2765 3.91041 12.2765 5.78958C12.2765 7.66875 10.5767 9.20625 8.49911 9.20625C6.42154 9.20625 4.72171 7.66875 4.72171 5.78958C4.72171 3.91041 6.42154 2.37291 8.49911 2.37291Z"
      fill={props.fill ?? 'white'}
    />
    <path
      d="M8.49915 12.708C3.7774 12.708 0 16.1247 0 20.3955C0 20.908 0.37774 21.2497 0.94435 21.2497C1.51096 21.2497 1.8887 20.908 1.8887 20.3955C1.8887 17.0643 4.81619 14.4163 8.49915 14.4163C12.1821 14.4163 15.1096 17.0643 15.1096 20.3955C15.1096 20.908 15.4873 21.2497 16.054 21.2497C16.6206 21.2497 16.9983 20.908 16.9983 20.3955C17.0927 16.1247 13.2209 12.708 8.49915 12.708Z"
      fill={props.fill ?? 'white'}
    />
  </SVGIcon>
);

export const LogoutIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="24" viewBox="0 0 24 24" width="24">
    <path
      d="M5 11.8002C5 12.3002 5.4 12.8002 6 12.8002H21.7L17.8 16.7002C17.6 16.9002 17.5 17.1001 17.5 17.4001C17.5 17.7001 17.6 17.9001 17.8 18.1001C18.2 18.5001 18.8 18.5001 19.2 18.1001L22.6 14.7002C23.4 13.9002 23.8 12.9002 23.8 11.8002C23.8 10.7002 23.4 9.70015 22.6 8.90015L19.3 5.60015C18.9 5.20015 18.3 5.20015 18 5.60015C17.6 6.00015 17.6 6.60016 18 7.00016L21.9 10.9001H6.2C5.5 10.8001 5 11.2002 5 11.8002Z"
      fill="#222222"
    />
    <path
      d="M8.70029 1C8.70029 0.5 8.30029 0 7.70029 0L2.90029 0C1.30029 0 0.000289917 1.29999 0.000289917 2.89999L0.000289917 20.8C0.000289917 22.4 1.30029 23.7 3.00029 23.7H7.80029C8.30029 23.7 8.80029 23.3 8.80029 22.7C8.80029 22.2 8.40029 21.7 7.80029 21.7H3.00029C2.40029 21.7 2.00029 21.2 2.00029 20.7L2.00029 2.8C2.00029 2.2 2.50029 1.8 3.00029 1.8L7.80029 1.8C8.20029 2 8.70029 1.5 8.70029 1Z"
      fill="#222222"
    />
  </SVGIcon>
);

export const Chevron: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="100%" viewBox="0 0 24 24" width="100%">
    <rect
      height="24"
      style={{ mixBlendMode: 'multiply' }}
      transform="matrix(-1 0 0 -1 24 24)"
      width="24"
    />
    <path
      d="M12 7.49999L19.5 15L18.45 16.05L12 9.59999L5.55 16.05L4.5 15L12 7.49999Z"
      fill="#653FFE"
    />
  </SVGIcon>
);

export const EmailIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="21" viewBox="0 0 24 21" width="24">
    <path
      d="M0 4.9668V16.0332C0 18.7344 2.27272 21 5.18182 21H18.8182C21.6364 21 24 18.8216 24 16.0332V4.9668C24 2.26556 21.7273 0 18.8182 0H5.18182C2.27272 0.087137 0 2.26556 0 4.9668ZM3 2.70124C3.54546 2.17842 4.36363 1.91701 5.0909 1.91701H18.7273C19.5455 1.91701 20.2727 2.17842 20.8182 2.70124L21.2727 3.13692L14 10.1079C13.3636 10.6307 12.6364 10.8921 11.9091 10.8921C11.1818 10.8921 10.3636 10.6307 9.81819 10.1079L2.54546 3.13692L3 2.70124ZM1.90908 5.14108L8.36364 11.3278C10.2727 13.1577 13.4545 13.1577 15.3636 11.3278L21.8182 5.14108V16.0332C21.8182 17.6888 20.3636 19.083 18.6364 19.083H5C3.27273 19.083 1.81818 17.6888 1.81818 16.0332L1.90908 5.14108Z"
      fill="#441078"
      id="Vector"
    />
  </SVGIcon>
);

export const PhoneIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="24" viewBox="0 0 24 24" width="24">
    <g id="icon_tel">
      <g id="Vector">
        <path
          d="M4 2C4 0.89543 4.89543 0 6 0H19C20.1046 0 21 0.895431 21 2V22C21 23.1046 20.1046 24 19 24H6C4.89543 24 4 23.1046 4 22V2Z"
          fill="#441078"
        />
        <path
          d="M6 2.5C6 2.22386 6.22386 2 6.5 2H18.5C18.7761 2 19 2.22386 19 2.5V10.5V18.5C19 18.7761 18.7761 19 18.5 19H6.5C6.22386 19 6 18.7761 6 18.5V2.5Z"
          fill="white"
        />
        <path
          d="M14 21.5C14 22.3284 13.3284 23 12.5 23C11.6716 23 11 22.3284 11 21.5C11 20.6716 11.6716 20 12.5 20C13.3284 20 14 20.6716 14 21.5Z"
          fill="white"
        />
      </g>
    </g>
  </SVGIcon>
);

export const DashboardIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="20" viewBox="0 0 20 20" width="20">
    <path
      d="M19.3333 7.58333L13 1.25C11.3333 -0.416667 8.75 -0.416667 7.08333 1.25L0.749995 7.58333C0.249995 8.08333 0 8.66666 0 9.33333V17.5C0 18.9167 1.08333 20 2.5 20H17.5C18.9167 20 20 18.9167 20 17.5V9.33333C20 8.66666 19.75 8 19.3333 7.58333ZM12.5 18.3333H7.5V15.0833C7.5 13.6667 8.58333 12.5833 10 12.5833C11.4167 12.5833 12.5 13.6667 12.5 15.0833V18.3333ZM18.3333 17.5C18.3333 18 18 18.3333 17.5 18.3333H14.1667V15.0833C14.1667 12.75 12.3333 10.9167 10 10.9167C7.66667 10.9167 5.83333 12.75 5.83333 15.0833V18.3333H2.5C2 18.3333 1.66667 18 1.66667 17.5V9.33333C1.66667 9.08333 1.75002 8.91667 1.91668 8.75L8.24999 2.41666C9.24999 1.41666 10.8333 1.41666 11.75 2.41666L18.0833 8.75C18.25 8.91667 18.3333 9.08333 18.3333 9.33333V17.5Z"
      fill={props.fill ?? 'white'}
    />
  </SVGIcon>
);

export const SupportIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="19" viewBox="0 0 20 19" width="20">
    <path
      d="M0 4.49378V14.5062C0 16.9502 1.89394 19 4.31818 19H15.6818C18.0303 19 20 17.029 20 14.5062V4.49378C20 2.04979 18.1061 0 15.6818 0H4.31818C1.89394 0.0788382 0 2.04979 0 4.49378ZM2.5 2.44398C2.95455 1.97095 3.63636 1.73444 4.24242 1.73444H15.6061C16.2879 1.73444 16.8939 1.97095 17.3485 2.44398L17.7273 2.83817L11.6667 9.14522C11.1364 9.61825 10.5303 9.85478 9.92424 9.85478C9.31818 9.85478 8.63637 9.61825 8.18182 9.14522L2.12121 2.83817L2.5 2.44398ZM1.5909 4.65145L6.9697 10.249C8.56061 11.9046 11.2121 11.9046 12.803 10.249L18.1818 4.65145V14.5062C18.1818 16.0042 16.9697 17.2656 15.5303 17.2656H4.16667C2.72727 17.2656 1.51515 16.0042 1.51515 14.5062L1.5909 4.65145Z"
      fill={props.fill ?? 'white'}
      id="Vector"
    />
  </SVGIcon>
);

export const CloseIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="33" viewBox="0 0 33 33" width="33">
    <g id="Frame 50">
      <path
        d="M9.45745 24.3211L16.2472 17.6766L22.8917 24.4664C23.3113 24.8952 23.877 24.9013 24.3058 24.4817C24.7347 24.062 24.7408 23.4964 24.3211 23.0676L17.6766 16.2778L24.4664 9.63331C24.8952 9.21366 24.9013 8.64801 24.4817 8.21918C24.062 7.79035 23.4964 7.78424 23.0675 8.20389L16.2778 14.8484L9.63331 8.05862C9.21365 7.62979 8.648 7.62367 8.21918 8.04333C7.79035 8.46298 7.78423 9.02863 8.20388 9.45746L14.8484 16.2472L8.05861 22.8917C7.62979 23.3114 7.62367 23.877 8.04332 24.3058C8.46297 24.7347 9.1001 24.6708 9.45745 24.3211Z"
        fill="white"
        id="Vector"
      />
    </g>
  </SVGIcon>
);

export const MenuIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="22" viewBox="0 0 22 22" width="33">
    <g id="Group">
      <path
        d="M21.2072 9.84473H0.79281C0.396414 9.84473 0 10.347 0 10.9497C0 11.5525 0.396414 12.0548 0.79281 12.0548H21.2072C21.6036 12.0548 22 11.5525 22 10.9497C22 10.4475 21.6036 9.84473 21.2072 9.84473Z"
        fill="white"
        id="Vector"
      />
      <path
        d="M21.2072 0H0.79281C0.396414 0 0 0.502282 0 1.10502C0 1.70776 0.396414 2.21004 0.79281 2.21004H21.2072C21.6036 2.21004 22 1.70776 22 1.10502C22 0.502282 21.6036 0 21.2072 0Z"
        fill="white"
        id="Vector_2"
      />
      <path
        d="M21.2072 19.79H0.79281C0.396414 19.79 0 20.2923 0 20.8951C0 21.4978 0.396414 22.0001 0.79281 22.0001H21.2072C21.6036 22.0001 22 21.4978 22 20.8951C22 20.2923 21.6036 19.79 21.2072 19.79Z"
        fill="white"
        id="Vector_3"
      />
    </g>
  </SVGIcon>
);

export const TrashIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="22" viewBox="0 0 24 24" width="33">
    <path
      d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);
export const DragHandleIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="22" viewBox="0 0 24 24" width="33">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 8C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5C8.67157 5 8 5.67157 8 6.5C8 7.32843 8.67157 8 9.5 8ZM9.5 14C10.3284 14 11 13.3284 11 12.5C11 11.6716 10.3284 11 9.5 11C8.67157 11 8 11.6716 8 12.5C8 13.3284 8.67157 14 9.5 14ZM11 18.5C11 19.3284 10.3284 20 9.5 20C8.67157 20 8 19.3284 8 18.5C8 17.6716 8.67157 17 9.5 17C10.3284 17 11 17.6716 11 18.5ZM15.5 8C16.3284 8 17 7.32843 17 6.5C17 5.67157 16.3284 5 15.5 5C14.6716 5 14 5.67157 14 6.5C14 7.32843 14.6716 8 15.5 8ZM17 12.5C17 13.3284 16.3284 14 15.5 14C14.6716 14 14 13.3284 14 12.5C14 11.6716 14.6716 11 15.5 11C16.3284 11 17 11.6716 17 12.5ZM15.5 20C16.3284 20 17 19.3284 17 18.5C17 17.6716 16.3284 17 15.5 17C14.6716 17 14 17.6716 14 18.5C14 19.3284 14.6716 20 15.5 20Z"
      fill="#121923"
    />
  </SVGIcon>
);
