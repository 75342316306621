import { FC, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth0 } from '@auth0/auth0-react';
import { PhoneNumberInput, TextInput } from '../../Inputs';
import { CustomerFieldsEnum } from '../../../types';
import { customerInformationFormSchema } from './customerInformationFormSchema';
import { useCustomerQuery } from '../../../hooks/api/customer/customer.generated';
import { useRequestPasswordResetMutation } from '../../../hooks/api/requestPasswordReset/requestPasswordReset.generated';

interface CustomerInformationProps {
  isEditable: boolean;
  closeEditMode: () => void;
}

export interface CustomerInformationValues {
  [CustomerFieldsEnum.CompanyName]: string;
  [CustomerFieldsEnum.TaxId]: string;
  [CustomerFieldsEnum.Country]: string;
  [CustomerFieldsEnum.City]: string;
  [CustomerFieldsEnum.PostalCode]: string;
  [CustomerFieldsEnum.Address]: string;
  [CustomerFieldsEnum.Email]: string;
  [CustomerFieldsEnum.PhoneNumber]: string;
  [CustomerFieldsEnum.Name]: string;
}
const CustomerInformation: FC<CustomerInformationProps> = ({ isEditable, closeEditMode }) => {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CustomerInformationValues>({
    resolver: yupResolver(customerInformationFormSchema()) as any,
  });

  const { data: { customer: customerData } = {} } = useCustomerQuery();
  const { user } = useAuth0();

  const phoneNumberValue = watch(CustomerFieldsEnum.PhoneNumber);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const maxWidthValue = isLargeScreen ? '288px' : '488px';

  useEffect(() => {
    const defaultValues = {
      [CustomerFieldsEnum.CompanyName]: customerData?.companyName,
      [CustomerFieldsEnum.TaxId]: customerData?.taxId,
      [CustomerFieldsEnum.Country]: customerData?.country,
      [CustomerFieldsEnum.City]: customerData?.city,
      [CustomerFieldsEnum.PostalCode]: customerData?.postalCode,
      [CustomerFieldsEnum.Address]: customerData?.address,
      [CustomerFieldsEnum.Email]: customerData?.email,
      [CustomerFieldsEnum.PhoneNumber]: customerData?.phoneNumber,
      [CustomerFieldsEnum.Name]: customerData?.name,
    };

    reset(defaultValues);
  }, [customerData]);

  const [requestPasswordReset] = useRequestPasswordResetMutation();
  const [isResetPasswordMailSent, setIsResetPasswordMailSent] = useState(false);
  const handleOnChangePasswordClick = async () => {
    await requestPasswordReset({ variables: { email: user?.email ?? '' } });
    setIsResetPasswordMailSent(true);
  };

  const onSubmit = async (values: CustomerInformationValues) => {
    closeEditMode();
    /* eslint-disable-next-line no-console */
    console.log(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12} xs={12}>
          <Typography fontSize="24px" fontWeight={700} pb={3}>
            Basic information
          </Typography>
          <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
            <TextInput
              {...register(CustomerFieldsEnum.CompanyName)}
              disabled={!isEditable}
              errors={errors}
              label="Company name"
              onChange={(value) => setValue(CustomerFieldsEnum.CompanyName, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.TaxId)}
              disabled={!isEditable}
              errors={errors}
              label="Tax ID"
              onChange={(value) => setValue(CustomerFieldsEnum.TaxId, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.Country)}
              disabled={!isEditable}
              errors={errors}
              label="Country"
              onChange={(value) => setValue(CustomerFieldsEnum.Country, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.City)}
              disabled={!isEditable}
              errors={errors}
              label="City"
              onChange={(value) => setValue(CustomerFieldsEnum.City, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.PostalCode)}
              disabled={!isEditable}
              errors={errors}
              label="Postal Code"
              onChange={(value) => setValue(CustomerFieldsEnum.PostalCode, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.Address)}
              disabled={!isEditable}
              errors={errors}
              label="Address"
              onChange={(value) => setValue(CustomerFieldsEnum.Address, value.target.value)}
            />
          </Box>
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <Typography fontSize="24px" fontWeight={700} pb={3}>
            Contact details
          </Typography>
          <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
            <TextInput
              {...register(CustomerFieldsEnum.Email)}
              disabled={!isEditable}
              errors={errors}
              label="E-mail"
              onChange={(value) => setValue(CustomerFieldsEnum.Email, value.target.value)}
            />
            <PhoneNumberInput
              disabled={!isEditable}
              errors={errors}
              initialValue={phoneNumberValue}
              label="Phone number"
              {...register(CustomerFieldsEnum.PhoneNumber)}
              onChange={(value) => setValue(CustomerFieldsEnum.PhoneNumber, value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.Name)}
              disabled={!isEditable}
              errors={errors}
              label="Name"
              onChange={(value) => setValue(CustomerFieldsEnum.Name, value.target.value)}
            />
            <Box>
              <Typography fontSize="24px" fontWeight={700} pb="16px" pt="8px">
                Password
              </Typography>
              <Button
                color="secondary"
                onClick={handleOnChangePasswordClick}
                style={{ textTransform: 'none', padding: '13px 16px', fontWeight: '700' }}
                variant="outlined"
              >
                {!isResetPasswordMailSent ? 'Change password' : 'Rest password again'}
              </Button>
              {isResetPasswordMailSent && (
                <Typography pt={1}>
                  We have sent a link to change your password to your email address
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomerInformation;
