import React, { FC, useCallback, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Paper,
  Snackbar,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import BackButton from '../Common/BackButton';
import { WidgetControllerFieldsEnum, removeTypenames } from '../types';

import { useCreateControllerMutation } from '../../hooks/api/createController/createController.generated';
import { AddNewControllerValues, controllersSchema } from './videoControllersSchema';
import { ControllersDocument } from '../../hooks/api/controllers/controllers.generated';
import { FullScreenTabView } from './FullscreenTabView';
import { SettingsTabView } from './SettingsTabView';
import { MinifiedTabView } from './MinifiedTabView';
import { useGetControllerByIdQuery } from '../../hooks/api/controller/controller.generated';
import { useUpdateControllerMutation } from '../../hooks/api/updateController/updateController.generated';
import { ActionData } from './ActionData.model';
import { appConfig } from '../../app-config';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';

// TODO move to different location
interface TabButtonProps {
  url: string;
  children: React.ReactNode;
}

const TabButton: React.FC<TabButtonProps> = ({ url, children }) => {
  const [tabRef, setTabRef] = useState<Window | null>(null);

  const handleClick = useCallback(() => {
    if (!tabRef || tabRef.closed) {
      const newTab = window.open(url, '_blank');
      setTabRef(newTab);
    } else {
      tabRef.focus();
      try {
        tabRef.location.href = url;
      } catch (e) {
        // If we can't access the location due to CORS, reload the page
        tabRef.location.reload();
      }
    }
  }, [url, tabRef]);

  return (
    // TODO style etc should be configurable
    <Button
      color="primary"
      onClick={handleClick}
      style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
      variant="contained"
    >
      {children}
    </Button>
  );
};

interface AddNewControllerProps {
  edit?: boolean;
}

type TabType = 'settings' | 'fullscreen' | 'minified';

const AddNewControllerView: FC<AddNewControllerProps> = ({ edit }) => {
  const { controllerId } = useParams();
  const customerSettingsMethods = useCustomerSettings();

  const editMode = !!controllerId && !!edit;

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AddNewControllerValues>({
    resolver: yupResolver(controllersSchema(editMode)),
    defaultValues: {
      [WidgetControllerFieldsEnum.IsActive]: true,
      [WidgetControllerFieldsEnum.InitialStageId]: '0',
      [WidgetControllerFieldsEnum.Layout]: {
        type: 'widget',
        widget: {
          design: {
            minified: {
              widgetSize: 'L',
              shape: 'circle',
            },
          },
        },
      },
    },
  });

  const formValues = watch();
  const [validationError, setValidationError] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<string | undefined>();
  const [confirmationNotification, setConfirmationNotification] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    setValidationError(true);
  }, [errors, backendError]);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [tab, setTab] = useState<TabType>('fullscreen');
  const switchTabs = (event: React.SyntheticEvent, newValue: TabType) => {
    setTab(newValue);
  };

  const maxWidthValue = isLargeScreen ? '368px' : '488px';
  const paperPadding = isLargeScreen ? '32px' : '16px';

  const [selectedStage, setSelectedStage] = useState<string>();
  const [actionDataList, setActionDataList] = useState<ActionData[]>([]);

  const [createController] = useCreateControllerMutation({
    refetchQueries: [{ query: ControllersDocument }],
  });

  const [updateControllerMutation, { data: updateResponse, error: updateError }] =
    useUpdateControllerMutation();

  const onSubmit = async (values: AddNewControllerValues) => {
    // TODO cleanup
    console.log('input', values);
    try {
      if (editMode) {
        // TODO cleanup
        console.log('edit mode', JSON.stringify(values, null, 2));

        const input = {
          ...removeTypenames(values),
          videoAssetsSelect: undefined, // a bit hacky due to how we handle video assets
        };
        // TODO cleanup
        console.log('input', JSON.stringify(input, null, 2));
        const response = await updateControllerMutation({
          variables: {
            id: controllerId!,
            input,
          },
        });
        setConfirmationNotification('Controller saved successfully');
        console.log('update reps', response);
      } else {
        // TODO add notification on failure
        const resp = await createController({
          variables: {
            input: values,
          },
        });
        console.log('save resp', resp);
        setConfirmationNotification('Controller created successfully');
        navigate(`/controllers/${resp.data?.createController.id}`);
      }
    } catch (err) {
      // TODO add notification
      console.log('create/update failed', (err as unknown as ApolloError).message);
      console.log('create/update failed', updateResponse, updateError);
      setBackendError(
        'Failed to create or update controller due to issues with the server. Please try again later or contact us.',
      );
    }
  };

  if (editMode) {
    const {
      data: controllerData,
      loading: controllerLoading,
      error: controllerError,
    } = useGetControllerByIdQuery({
      variables: {
        id: controllerId!,
      },
    });

    useEffect(() => {
      if (controllerLoading) {
        // TODO loading bar!
        console.log('loading');
      }

      if (controllerError) {
        alert(`Error loading controller ${controllerError}`);
        console.error(controllerError);
      }

      if (controllerData) {
        console.log('controllerData', JSON.stringify(controllerData, null, 2));
        setValue(WidgetControllerFieldsEnum.IsActive, controllerData.controller.isActive);
        if (controllerData.controller.validFrom) {
          setValue(WidgetControllerFieldsEnum.ValidFrom, controllerData.controller.validFrom);
        }
        if (controllerData.controller.validUntil) {
          setValue(WidgetControllerFieldsEnum.ValidUntil, controllerData.controller.validUntil);
        }
        if (controllerData.controller.url) {
          setValue(WidgetControllerFieldsEnum.Url, controllerData.controller.url);
        }
        setValue(
          WidgetControllerFieldsEnum.InitialStageId,
          controllerData.controller.initialStageId,
        );
        setValue(WidgetControllerFieldsEnum.Name, controllerData.controller.name);
        const initialStage = controllerData.controller.stages.find(
          (stage) => stage.id === controllerData.controller.initialStageId,
        );
        if (initialStage) {
          setValue(WidgetControllerFieldsEnum.Stages, [
            {
              id: controllerData.controller.initialStageId,
              name: initialStage.name != null ? initialStage.name : undefined,
              content: {
                id: initialStage.id,
                type: 'video',
                videoAssetId: initialStage.content?.videoAssetId,
                videoAssetName: initialStage?.content?.videoAssetName,
                videoAssetUrl: initialStage?.content?.videoAssetUrl,
              },
              actions: removeTypenames(initialStage.actions),
            },
          ]);
          setSelectedStage(initialStage.content.id);
          setActionDataList(initialStage.actions as ActionData[]);
        }
        if (controllerData.controller.layout?.type === 'widget') {
          setValue(WidgetControllerFieldsEnum.Layout, {
            type: 'widget',
            // TODO a bit hacky!
            widget: controllerData.controller!.layout!.widget as any,
          });
        }
      }
    }, [controllerData, controllerLoading, controllerError]);
  }

  // TODO passing the form controls is very messy. Should consider provider/context? or just pass them in consistent way

  return (
    <Box>
      <BackButton label="Controllers" path="/controllers" />
      <Typography fontSize={36} fontWeight={700} pb={6} pt={4}>
        {editMode ? 'Edit controller' : 'New controller'}
      </Typography>
      <Paper style={{ padding: paperPadding }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={switchTabs}>
                  <Tab label="Choose video and actions" value="fullscreen" />
                  <Tab label="Minified" value="minified" />
                  <Tab label="Display settings" value="settings" />
                </TabList>
              </Box>
              <TabPanel value="fullscreen">
                <FullScreenTabView
                  register={register}
                  errors={errors}
                  editMode={editMode}
                  formValues={formValues}
                  maxWidthValue={maxWidthValue}
                  selected={selectedStage}
                  setValue={setValue}
                  control={control}
                  actionDataList={actionDataList}
                  setActionDataList={setActionDataList}
                />
              </TabPanel>
              <TabPanel value="minified">
                <MinifiedTabView
                  formValues={formValues}
                  maxWidthValue={maxWidthValue}
                  register={register}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value="settings">
                <SettingsTabView
                  control={control}
                  watch={watch}
                  errors={errors}
                  maxWidthValue={maxWidthValue}
                  register={register}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value="actions">Actions</TabPanel>
              <TabPanel value="design">Design</TabPanel>
            </TabContext>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="end" pt={6} width="100%">
            {editMode && (
              <TabButton
                url={`${appConfig.previewSiteUrl}?controllerId=${controllerId}&clientId=${customerSettingsMethods?.settings?.clientId}`}
              >
                Preview
              </TabButton>
            )}
            <Button
              color="secondary"
              style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </form>
      </Paper>
      {Object.values(errors).length > 0 && (
        <Snackbar
          autoHideDuration={5000}
          onClose={() => setValidationError(false)}
          open={validationError}
        >
          <Alert severity="error">
            {Object.values(errors).map((error, index) => (
              <div key={index}> - {error.message as string}</div>
            ))}
          </Alert>
        </Snackbar>
      )}
      {backendError && (
        <Snackbar
          autoHideDuration={5000}
          onClose={() => setBackendError(undefined)}
          open={!!backendError}
        >
          <Alert severity="error">
            <div>{backendError}</div>
          </Alert>
        </Snackbar>
      )}
      {confirmationNotification && (
        <Snackbar
          autoHideDuration={5000}
          onClose={() => setConfirmationNotification(undefined)}
          open={!!confirmationNotification}
        >
          <Alert severity="success">
            <div>{confirmationNotification}</div>
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default AddNewControllerView;
