import React from 'react';
import { Snackbar, Alert } from '@mui/material';

interface ErrorSnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({ open, message, onClose }) => (
  <Snackbar autoHideDuration={6000} onClose={onClose} open={open}>
    <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
      Error: Connecting to backend. [{message}]
    </Alert>
  </Snackbar>
);

export default ErrorSnackbar;
