export enum HomeTabEnum {
  Video = 'video',
  CustomerInformation = 'customerInformation',
}

export enum CustomerFieldsEnum {
  CompanyName = 'companyName',
  TaxId = 'taxId',
  Country = 'country',
  City = 'city',
  PostalCode = 'postalCode',
  Address = 'address',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Name = 'name',
}
