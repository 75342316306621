import React, { useState, useCallback } from 'react';
import {
  AppBar,
  Badge,
  Box,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  Drawer,
  Alert,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { theme } from '../styles/theme';
import { Closer2Icon, CustomerIcon, LogoutIcon, MenuIcon, CloseIcon } from '../assets/icons';
import PanelSidebar from './PanelSidebar';

const drawerWidth = 240;

const PanelLayout = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMobileMenuToggle = useCallback(() => {
    setMobileMenuOpen((prev) => !prev);
  }, []);

  const handleLogoutClick = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const handleMobileMenuClose = useCallback(() => {
    setMobileMenuOpen(false);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: {
            xs: theme.palette.primary.main,
            sm: theme.palette.background.default,
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'space-between', sm: 'flex-end' },
          }}
        >
          {isMobile && (
            <Box sx={{ height: '17px', width: '96px' }}>
              <Closer2Icon />
            </Box>
          )}
          <Box>
            <IconButton onClick={handleMenuClick}>
              <Badge>
                <CustomerIcon fill={!isMobile ? '#222222' : '#FFFFFF'} />
                {!isMobile && (
                  <Typography color="#000" pl={2}>
                    {user?.email}
                  </Typography>
                )}
              </Badge>
            </IconButton>
            <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={Boolean(anchorEl)}>
              <MenuItem onClick={handleLogoutClick}>
                <LogoutIcon />
                Log Out
              </MenuItem>
            </Menu>
            {isMobile && (
              <IconButton
                aria-label="open drawer"
                color="inherit"
                edge="start"
                onClick={handleMobileMenuToggle}
                sx={{ ml: 2 }}
              >
                {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            )}
            {user && !user.email_verified && (
              <Alert severity="warning">Please verify your email</Alert>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {isMobile ? (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.primary.main,
            zIndex: theme.zIndex.drawer + 1,
            transform: mobileMenuOpen ? 'translateX(0)' : 'translateX(-100%)',
            transition: theme.transitions.create('transform', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <Box sx={{ marginTop: '54px' }}>
            <PanelSidebar drawerWidth="100%" isMobile onItemClick={handleMobileMenuClose} />
          </Box>
        </Box>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          <PanelSidebar drawerWidth={drawerWidth} isMobile={false} />
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginTop: { xs: '56px', sm: '64px' },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(PanelLayout);
