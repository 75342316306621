import { Box, Link, Paper, Typography } from '@mui/material';
import { EmailIcon, PhoneIcon } from '../../assets/icons';

const Support = () => {
  const email = 'closer2@xyz.com';
  const phoneNumber = '+48 444 444 444';
  return (
    <Box>
      <Typography fontSize={36} fontWeight={700} pb="24px" pt="44px">
        Support
      </Typography>
      <Paper style={{ padding: '32px', height: '70vh' }}>
        <Typography fontSize={24} fontWeight={700} pb="16px">
          Do you need help?{' '}
        </Typography>
        <Typography fontSize={18} pb="32px">
          Contact us and we will reply as soon as possible.
        </Typography>
        <Box
          alignItems="center"
          bgcolor="#F4F4F4"
          borderRadius="8px"
          display="flex"
          flexDirection="row"
          gap="8px"
          maxWidth={252}
          padding="20px"
        >
          <EmailIcon />
          <Link href={`mailto:${email}`} underline="none">
            {email}
          </Link>
        </Box>
        <Box
          alignItems="center"
          bgcolor="#F4F4F4"
          borderRadius="8px"
          display="flex"
          flexDirection="row"
          gap="8px"
          marginTop="16px"
          maxWidth={252}
          padding="20px"
        >
          <PhoneIcon />
          <Link href={`tel:${phoneNumber}`} underline="none">
            {phoneNumber}
          </Link>
        </Box>
      </Paper>
    </Box>
  );
};

export default Support;
