import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { GetViewerDataQuery } from '../../hooks/api/viewerData/viewerData.generated';

interface ViewerDataProps {
  data: GetViewerDataQuery;
}

const ViewerDataTable = ({ data }: ViewerDataProps) => {
  return (
    <TableContainer sx={{ borderRadius: '8px 8px 0px 0px' }}>
      <Table aria-labelledby="tableTitle" sx={{ minWidth: 750 }}>
        <TableHead>
          <TableRow>
            <TableCell>Submission Type</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>ControllerId</TableCell>
            <TableCell>StageId</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.viewerData.data.map((item, index) => {
            const labelId = `viewer-data-table-${index}`;

            return (
              <TableRow
                key={item.id}
                hover
                role="checkbox"
                sx={{ cursor: 'pointer', background: '#FFF' }}
                tabIndex={-1}
              >
                <TableCell component="th" id={labelId} scope="row" sx={{ fontSize: '16px' }}>
                  {item.submissionType}
                </TableCell>
                <TableCell sx={{ fontSize: '16px' }}>
                  <Box>
                    {item.data.email && <Typography>Email: {item.data.email}</Typography>}
                    {item.data.phone && <Typography>Phone: {item.data.phone}</Typography>}
                    {item.data.text && <Typography>Text: {item.data.text}</Typography>}
                  </Box>
                </TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{item.status}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>
                  {new Date(item.createdAt).toLocaleString()}
                </TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{item.controllerId}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{item.stageId}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ViewerDataTable;
