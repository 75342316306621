// src/components/AssetsView.tsx
import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableViewToolbar from '../Common/TableViewToolbar';
import { useAssetsQuery, AssetsQuery } from '../../hooks/api/assets/assets.generated';
import { LoadingSpinner } from '../Utils';
import ErrorSnackbar from '../Utils/ErrorSnackbar';
import { useDeleteAssetMutation } from '../../hooks/api/deleteAsset/deleteAsset.generated';

type Asset = AssetsQuery['assets']['assets'][0] & { previewImage: string }; // TODO a bit hacky..

interface AssetCardProps {
  asset: Asset;
  onEdit: () => void;
  onRemove: () => void;
}

const AssetCard: React.FC<AssetCardProps> = ({ asset, onEdit, onRemove }) => {
  const formatDuration = (duration: number): string => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const formatBytesToMB = (bytes: number): string => {
    return (bytes / 1048576).toFixed(2);
  };

  return (
    <Card>
      <CardMedia
        alt={asset.name}
        component="img"
        height="140"
        image="../wideo_placeholder-min.png"
      />
      <CardContent>
        <Typography component="div" variant="h6">
          {asset.name}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Name: {asset.videoAsset.name}
        </Typography>
        {asset.videoAsset.resolution && (
          <Typography color="text.secondary" variant="body2">
            Resolution: {asset.videoAsset.resolution}
          </Typography>
        )}
        {asset.videoAsset.size && (
          <Typography color="text.secondary" variant="body2">
            Size: {formatBytesToMB(asset.videoAsset.size)} MB
          </Typography>
        )}
        {asset.videoAsset.duration && (
          <Typography color="text.secondary" variant="body2">
            Duration: {formatDuration(asset.videoAsset.duration)}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        {/* <Button onClick={onEdit} size="small" aria-label={`Edit ${asset.name}`}>
          Edit
        </Button> */}
        <Button onClick={onRemove} size="small" aria-label={`Remove ${asset.name}`}>
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

const AssetsView: React.FC = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);

  const { data: assetsData, loading, error, refetch } = useAssetsQuery();

  const [deleteAssetMutation, { error: deleteAssetError }] = useDeleteAssetMutation();

  const rows: Asset[] =
    assetsData?.assets.assets.map((a) => ({
      previewImage: 'https://www.clapa.com/wp-content/uploads/2019/06/video-placeholder.jpg',
      id: a.id,
      customerId: a.customerId,
      name: a.name,
      createdAt: a.createdAt,
      updatedAt: a.updatedAt,
      deletedAt: a.deletedAt,
      videoAsset: {
        fileId: a.videoAsset.fileId,
        name: a.videoAsset.name,
        resolution: a.videoAsset.resolution,
        size: a.videoAsset.size,
        duration: a.videoAsset.duration,
      },
    })) || [];

  if (loading) return <LoadingSpinner />;
  if (error || deleteAssetError) {
    if (!errorSnackbarOpen) {
      setErrorSnackbarOpen(true);
    }
  }

  const handleCloseErrorSnackbar = (): void => {
    setErrorSnackbarOpen(false);
  };

  const onRemove = async (id: string) => {
    try {
      await deleteAssetMutation({ variables: { id } });
      await refetch();
    } catch (err) {
      console.error('Failed to delete asset', err);
    }
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Box
        display="flex"
        flexDirection={isLargeScreen ? 'row' : 'column'}
        justifyContent="space-between"
        pb={isLargeScreen ? '20px' : '40px'}
        width="100%"
      >
        <TableViewToolbar title="Video library" />
        <Button
          color="secondary"
          onClick={() => navigate('/assets/add-new-asset')}
          sx={{
            padding: isLargeScreen ? '0 90px' : '10px 20px',
            textTransform: 'none',
            maxHeight: '48px',
          }}
          variant="outlined"
        >
          Add new video
        </Button>
      </Box>

      {rows.length === 0 ? (
        <Typography>No assets found. Add a new video to get started.</Typography>
      ) : (
        <Grid container spacing={2}>
          {rows.map((asset) => (
            <Grid key={asset.id} item lg={3} md={4} sm={6} xs={12}>
              <AssetCard
                asset={asset}
                onEdit={() => navigate(`/assets/${asset.id}`)}
                onRemove={() => onRemove(asset.id)}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <ErrorSnackbar
        message={error?.message || deleteAssetError?.message || 'Unknown error'}
        onClose={handleCloseErrorSnackbar}
        open={errorSnackbarOpen}
      />
    </Box>
  );
};

export default AssetsView;
