import { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Box, Button, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useCreateCheckoutSessionMutation } from '../../hooks/api/checkout/checkout.generated';
import { useGetSubscriptionPlansQuery } from '../../hooks/api/subscriptionPlans/subscriptionPlans.generated';
import { useAuth } from '../../hooks/useAuth';

// TODO ogarnij webhook i subscripcje?
// TODO error handling notification snackbar
// TODO empty list handling
// TODO graphql settings call fails "ForbiddenException: Empty Authorization header"
export function SubscriptionPlansList() {
  // const { isAuthenticated, isLoading, loginAndRedirectBack } = useAuth();

  // useEffect(() => {
  //   if (!isLoading && !isAuthenticated) {
  //     const fullPath = `${window.location.origin}${window.location.pathname}`;
  //     loginAndRedirectBack(fullPath);
  //   }
  // }, [isLoading, isAuthenticated, loginAndRedirectBack]);

  const stripe = useStripe();
  const { data, loading, error } = useGetSubscriptionPlansQuery({
    variables: {},
  });
  const [
    createCheckoutSessionMutation,
    { loading: checkoutSessionLoading, error: checkoutSessionError },
  ] = useCreateCheckoutSessionMutation();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleCheckout = async (planId: string, stripePriceId: string) => {
    if (!stripe || !planId) return;

    try {
      const result = await createCheckoutSessionMutation({
        variables: {
          planId,
        },
      });

      console.log('Result:', result);

      if (result.data?.createCheckoutSession?.sessionId) {
        await stripe.redirectToCheckout({
          sessionId: result.data.createCheckoutSession.sessionId,
        });
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  return data?.getSubscriptionPlans.subscriptionPlans.map((plan) => (
    <Container maxWidth="sm">
      <Box
        key={plan.id}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={3}
        marginTop={5}
        border={1}
        borderRadius={2}
        borderColor="grey.300"
      >
        <Typography variant="h5" component="h3" gutterBottom>
          {plan.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {plan.description}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Price: {plan.price} {plan.currency}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Limits:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary={`View: ${plan.limits.viewLimit}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Play: ${plan.limits.playLimit}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Domain: ${plan.limits.domainLimit}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Video: ${plan.limits.videoLimit}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Controller: ${plan.limits.controllerLimit}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Action: ${plan.limits.actionLimit}`} />
          </ListItem>
        </List>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCheckout(plan.id, plan.stripePriceId)}
          style={{ marginTop: '16px' }}
        >
          Checkout
        </Button>
      </Box>
    </Container>
  ));
}
