// context/CustomerSettingsContext.tsx

import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { usePatchCustomerSettingsMutation } from '../hooks/api/patchCustomerSettings/patchCustomerSettings.generated';
import { useCustomerSettingsQuery } from '../hooks/api/customerSettings/customerSettings.generated';
import { UpdateUserSettingsTypeInput, UserSettingsType } from '../interfaces';

type CustomerSettingsProviderProps = {
  children: React.ReactNode;
};

type SettingsContextType = {
  settings: UserSettingsType | null;
  queryLoading: boolean;
  mutationLoading: boolean;
  queryError: Error | undefined; // Simplified for illustration
  mutationError: Error | undefined; // Simplified for illustration
  updateSettings: (newSettings: UserSettingsType) => Promise<void>;
} | null;

const CustomerSettingsContext = createContext<SettingsContextType>(null);

export const SettingsProvider: React.FC<CustomerSettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState<UserSettingsType | null>(null);
  const { data, loading: queryLoading, error: queryError, refetch } = useCustomerSettingsQuery({});
  const [patchCustomerSettingsMutation, { loading: mutationLoading, error: mutationError }] =
    usePatchCustomerSettingsMutation();

  useEffect(() => {
    if (data) {
      setSettings(data.customerSettings);
    }
  }, [data]);

  // TODO fix it
  const updateSettings = async (newSettings: UserSettingsType) => {
    // const { trustedDomains, freshworksIsActive, freshworksApiKey } = newSettings;
    // await patchCustomerSettingsMutation({
    //   variables: {
    //     trustedDomains,
    //   },
    // }).then(() => {
    //   refetch();
    // });
    // TODO refactor to use the new settings object
    // setSettings({
    //   ...settings,
    //   trustedDomains,
    //   crmIntegration: {
    //     freshworks: {
    //       isActive: freshworksIsActive,
    //       apiKey: freshworksApiKey,
    //     },
    //   },
    // });
  };

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      settings,
      queryLoading,
      mutationLoading,
      queryError,
      mutationError,
      updateSettings,
    }),
    [settings],
  );

  return (
    <CustomerSettingsContext.Provider value={contextValue}>
      {children}
    </CustomerSettingsContext.Provider>
  );
};

export const useCustomerSettings = () => useContext(CustomerSettingsContext);
