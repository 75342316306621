import { useMemo } from 'react';

const featureFlags = {
  customerForm: process.env.REACT_APP_FEATURE_FLAG_EDIT_FORM !== 'false',
};

const useFeatureFlags = () => {
  const memoizedFeatureFlags = useMemo(() => featureFlags, []);

  return memoizedFeatureFlags;
};

export default useFeatureFlags;
