import { Box, FormControl, FormHelperText, styled, TextField, Typography } from '@mui/material';
import React, { ChangeEventHandler } from 'react';

interface CustomLabelInputProps {
  label: string;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  errors?: any;
  name?: string;
}

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
});

const TextInput = React.forwardRef<HTMLInputElement, CustomLabelInputProps>(
  ({ label, disabled, value, onChange, placeholder, errors, name, ...props }, ref) => {
    const fieldError = errors && errors[name ?? ''];
    return (
      <Box>
        <FormControl fullWidth>
          <Typography pb={1}>{label}</Typography>
          <StyledTextField
            {...props}
            disabled={disabled}
            InputProps={{
              style: {
                backgroundColor: disabled ? '#F4F4F4' : 'white',
              },
            }}
            inputRef={ref}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            variant="outlined"
          />
        </FormControl>
        {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
      </Box>
    );
  },
);

export default TextInput;
