import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type CustomerSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CustomerSettingsQuery = {
  __typename?: 'Query';
  customerSettings: {
    __typename?: 'UserSettingsType';
    clientId: string;
    trustedDomains: Array<string>;
    crmIntegration: {
      __typename?: 'CRMIntegrationType';
      freshworks?: {
        __typename?: 'FreshworksCRMIntegrationType';
        isActive: boolean;
        apiKey?: string | null;
      } | null;
    };
  };
};

export const CustomerSettingsDocument = gql`
  query CustomerSettings {
    customerSettings {
      clientId
      trustedDomains
      crmIntegration {
        freshworks {
          isActive
          apiKey
        }
      }
    }
  }
`;

/**
 * __useCustomerSettingsQuery__
 *
 * To run a query within a React component, call `useCustomerSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerSettingsQuery, CustomerSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerSettingsQuery, CustomerSettingsQueryVariables>(
    CustomerSettingsDocument,
    options,
  );
}
export function useCustomerSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerSettingsQuery, CustomerSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerSettingsQuery, CustomerSettingsQueryVariables>(
    CustomerSettingsDocument,
    options,
  );
}
export type CustomerSettingsQueryHookResult = ReturnType<typeof useCustomerSettingsQuery>;
export type CustomerSettingsLazyQueryHookResult = ReturnType<typeof useCustomerSettingsLazyQuery>;
export type CustomerSettingsQueryResult = Apollo.QueryResult<
  CustomerSettingsQuery,
  CustomerSettingsQueryVariables
>;
export function refetchCustomerSettingsQuery(variables?: CustomerSettingsQueryVariables) {
  return { query: CustomerSettingsDocument, variables };
}
