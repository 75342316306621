// components/SignupForm.tsx
import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { appConfig } from '../../app-config';

const SignupForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const { signUp, clearSession } = useAuth();

  clearSession();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    if (!isChecked) {
      setError('You must agree to the rules and conditions.');
      return;
    }
    try {
      await signUp(email, password, name);
    } catch (err) {
      setError(`${(err as ApolloError).message}. Failed to sign up. Please try again.`);
      console.error(err);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h5" component="h1" gutterBottom>
          Closer2
        </Typography>
        <Typography variant="h3" component="h1" gutterBottom>
          Sign up
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="checked"
                color="primary"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            }
            label={
              <div>
                <span>I accept the </span>
                <Link href={`${appConfig.landingPageUrl}/rules-and-conditions`}>
                  terms of use & privacy policy
                </Link>
              </div>
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: '24px 0 16px' }}
          >
            Sign Up
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
        </form>
      </Box>
    </Container>
  );
};

export default SignupForm;
