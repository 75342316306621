import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type CustomerQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CustomerQuery = {
  __typename?: 'Query';
  customer: {
    __typename?: 'CustomerType';
    id: string;
    name: string;
    companyName: string;
    taxId: string;
    country: string;
    city: string;
    postalCode: string;
    address: string;
    email: string;
    phoneNumber: string;
  };
};

export const CustomerDocument = gql`
  query customer {
    customer {
      id
      name
      companyName
      taxId
      country
      city
      postalCode
      address
      email
      phoneNumber
    }
  }
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export function useCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export function refetchCustomerQuery(variables?: CustomerQueryVariables) {
  return { query: CustomerDocument, variables };
}
